import { Link } from "react-router-dom";
import logo from '../img/logo.svg';
import BlogMenu from './BlogMenu';
import ArchiveMenu from "./ArchiveMenu";

export default function Header({blogEntries, config}) {
  return (
    <header className="header">
      <div className='header__content'>
        <div>
          <Link to="/">
            <img src={logo} alt="Logo" className="header-logo" />
          </Link>
        </div>
        <nav className='header__links'>
          <ul>
            <li>
              <Link to="/politicas">Políticas</Link>
            </li>
            <li>
              <Link to="/industria">Industria</Link>
            </li>
            <li>
              <Link to="/vivienda">Vivienda</Link>
            </li>
            <li>
              <Link to="/periferias">Periferias</Link>
            </li>
            <li>
              <Link to="/territorio">Territorio</Link>
            </li>
          </ul>
          <ul>
            <BlogMenu blogEntries={blogEntries} />
            <ArchiveMenu titles={config.titles} glossary={config.glossary} />
          </ul>
        </nav>
      </div>
    </header>
  );
}
