import { useState } from 'react';
  
export default function BlogMenu({blogEntries}) {
  const [visibleBlogMenu, setVisibleBlogMenu] = useState(false);
  return(
    <>
      <div
        className="nav--hovereable"
        id="headerLinkBlog"
        onClick={() => {
          handleBlogMenu(visibleBlogMenu);
          setVisibleBlogMenu(!visibleBlogMenu)
        }}
      >
        <li>
          <span>Narrativas</span>
        </li>
        <nav className="header-blog-menu" id="headerBlogMenuDropdown">
          { blogEntries.map(entry=> <a href={entry.externalLink} key={entry._id}>{entry.title}</a>) }
        </nav>
      </div>
    </>
    )
}

function handleBlogMenu(visibleBlogMenu) {
  const dropdown = document.getElementById('headerBlogMenuDropdown');
  const headerItemText = document.getElementById('headerLinkBlog').querySelector('span')
  if (!visibleBlogMenu && window.innerWidth < 800) {
    dropdown.style.display = 'block';
    headerItemText.innerText = 'x Narrativas';
    headerItemText.classList.add('selectedMenuItem');
  } else if (window.innerWidth < 800) {
    dropdown.style.display = 'none';
    headerItemText.innerText = 'Narrativas';
    headerItemText.classList.remove('selectedMenuItem');
  }
}
