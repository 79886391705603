export default function Contact() {
  return (
    <div className="contact about">
      <section>
        <h2>¿Qué es el Archivo Ciudades de Octubre?</h2>
        <p>
          Es un repositorio digital público y colaborativo de memoria urbana. Su objetivo principal es recopilar un conjunto de documentos sobre la historia de poblaciones y campamentos en Chile, de políticas y operaciones urbanas, así como de sus habitantes y narrativas de la ciudad. Su compilación permite visibilizar y difundir archivos históricos de los últimos 60 años a un público ampliado, quienes pueden a su vez retroalimentar con su propio material tanto la plataforma como sus propias historias.
          <br/><br/>
          El Archivo Ciudades de Octubre comprende una labor de recopilación, digitalización y catalogación de más de 7 años, que ha dado como resultado un exhaustivo banco de antecedentes y fuentes bibliográficas en continuo crecimiento. Esta página web es el esfuerzo visible para reunir y dejar a disposición de un público mayor el material de archivo en bruto. Creemos que estos documentos pueden formar la fuente tanto de futuras investigaciones y discusiones como de la memoria colectiva de nuestras ciudades.
          <br/><br/>
          Para navegar en él, debes pinchar el botón ‘archivo’ en el menú superior. Allí, podrás clasificar el material según tipo de documento (prensa, planos, fotografías, etc.), así como también por palabras clave (erradicación, allegados, campamentos, pobladores, etc.)
        </p>
      </section>
      <hr className="dashed-red"></hr>
      <section>
        <h2>¿Quieres colaborar?</h2>
        <p>
          Si te interesa la historia urbana y has levantado material de archivo al respecto, te invitamos a construir juntxs la historia de la vivienda y las políticas urbanas en Chile. Rellena el formulario y te contactaremos para sumar tu material al Archivo.
          <br/><br/>
          Si has investigado temas urbanos y de la ciudad y te interesaría enviar un ensayo para publicar en Narrativas, escríbenos a <a href="mailto:contacto@ciudadesdeoctubre.cl">contacto@ciudadesdeoctubre.cl</a> con tu propuesta.
        </p>
      </section>
      <form>
        <div className="contactInputRow">
        <div className="contactInputItem">
          <label name="name">Nombre completo</label>
          <input type="text" name="name" placeholder="Ejemplo: Sophie Torres" />
        </div>
        
        <div className="contactInputItem">
          <label name="email">Correo</label>
          <input type="email" name="email" placeholder="Ejemplo: sophie@example.com"/>
          </div>
        </div>

        <div className="contactInputItem">
          <label>Mensaje</label>
          <textarea/>
        </div>

        <div className="contactInputItem">
          <input type="submit" value="Enviar"/>
        </div>
      </form>
    </div>
  );
  }