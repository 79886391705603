import React, { useEffect, useState, useRef } from "react";
import grayPlaceholder from '../img/gray-placeholder.svg';
import { useLocation, useNavigate } from 'react-router-dom';
const axios = require('axios').default;

export default function Archive (){
  const { state } = useLocation();
  const [currentSearch, setcurrentSearch] = useState('');
  const [archives, setArchives] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortType, setSortType] = useState('default');
  const azRef = useRef(null);
  const zaRef = useRef(null);
  const ascRef = useRef(null);
  const desRef = useRef(null);

  useEffect(() => {
    if(sortType === "default") {
      axios.post('https://cdo-api.onrender.com/api/searchArchive', { query: state })
        .then(response => {
          setArchives(response.data);
          setLoading(false);
          setcurrentSearch(state);
        });
    }

      if (sortType === "AZ") {
        setArchives([...archives].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))));
        azRef.current.style.textDecoration = 'line-through #E23340';
        zaRef.current.style.textDecoration = 'none';
        ascRef.current.style.textDecoration = 'none';
        desRef.current.style.textDecoration = 'none';
      } else if (sortType === "ZA") {
        setArchives([...archives].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))).reverse());
        azRef.current.style.textDecoration = 'none';
        zaRef.current.style.textDecoration = 'line-through #E23340';
        ascRef.current.style.textDecoration = 'none';
        desRef.current.style.textDecoration = 'none';
      } else if (sortType === "ASC") {
        setArchives([...archives].sort((a, b) => new Date(b.date) - new Date(a.date)).reverse());
        azRef.current.style.textDecoration = 'none';
        zaRef.current.style.textDecoration = 'none';
        ascRef.current.style.textDecoration = 'line-through #E23340';
        desRef.current.style.textDecoration = 'none';
      } else if (sortType === "DES") {
        setArchives([...archives].sort((a, b) => new Date(b.date) - new Date(a.date)));
        azRef.current.style.textDecoration = 'none';
        zaRef.current.style.textDecoration = 'none';
        ascRef.current.style.textDecoration = 'none';
        desRef.current.style.textDecoration = 'line-through #E23340';
      }
   }, [state, sortType]);

  if(loading) {
    return (
      <>
        <div className="breadcrumb">Búsqueda / {state}</div>
        {archives.length > 1 && (
          <p>listo</p>
        )}
      </>
    );
  } else if(!loading && archives.length === 0 && currentSearch === state) {
    return (
      <>
      <div className="breadcrumb">Búsqueda / {state}</div>
        <h2>No se encontraron resultados para: {state}</h2>
      </>
    )
  } else if (currentSearch === state){
    return (<>
      <div className="breadcrumb">Búsqueda / {state}</div>
      <div className="housing-intro">
        <h2 className="page-title">Resultado búsqueda: {state}</h2>
      </div>
      <hr className="dashed-red opacity05"></hr>
      <div className="sort-options">
        <div className="sort-options-title">Ordenar</div>
        <div className="sort-dropdown">
          <div onClick={() => setSortType("AZ")} ref={azRef} >A-Z</div>
          <div onClick={() => setSortType("ZA")} ref={zaRef}>Z-A</div>
          <div onClick={() => setSortType("ASC")} ref={ascRef}>Fecha ASC</div>
          <div onClick={() => setSortType("DES")} ref={desRef}>Fecha DES</div>
        </div>
      </div>
      <div className="archive-results-content">
        {archives.map(archive => <ArchiveItem key={archive._id} item={archive}/>)}
      </div>
    </>)
  } else {
    return (
      <>
      <div className="breadcrumb">Búsqueda / {state}</div>
        <h2>No se encontraron resultados para: {state}</h2>
      </>
    )
  }
}

function ArchiveItem({item}) {
  const navigate = useNavigate();
  return(<>
    <div
      className="archive-item-result"
      onClick={()=> navigate(`/item?search=${item.referenceCode}`, {state: item}) }
    >
      <img src={item.thumbnail ? item.thumbnail : grayPlaceholder } className="archive-item-image" alt={item.thumbnail ? item.thumbnail : grayPlaceholder } />  
      <h4 className="archive-item-title">{item.title}</h4>
      <h5 className="archive-item-subtitle">{item.subtitle}</h5>
    </div>
  </>)
}
