import logoPrincipal from '../img/logo-footer.svg';
import logoArchivo from '../img/logo-archivo.svg';
import logoFondart from '../img/fondart.svg';
import igLogo from '../img/ig-logo.svg';
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="footer">
        <div className="footer__content">
            <div className="footer__logo-row">
              <img src={logoPrincipal} alt="Logo principal" />
              <img src={logoArchivo} alt="Logo archivo" />
            </div>
            <hr className="dashed" />
            <nav className="footer__links">
              <div className="footer__column">
                <a href="https://www.instagram.com/ciudadesdeoctubre/" target="_blank" rel="noreferrer">
                  <img src={igLogo} alt="Instagram" className="ig-logo" />
                </a>
                <p><Link to="/about">Sobre nosotrxs</Link></p>
              </div>
              <div  className="footer__column">
                <p><Link to="/contact">¿Quieres colaborar?</Link></p>
                <img src={logoFondart} alt="Logo fondart" />
              </div>
            </nav>
        </div>
    </footer>
  );
}
