import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import logoFondart from "../img/fondart-black.svg";

export default function About({ children }) {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.querySelector("main").classList.remove("main__content");

    return function cleanup() {
      document.querySelector("main").classList.add("main__content");
    };
  }, [location]);

  return (
    <div className="about">
      {children}
      <div className="about__content">
        <section>
          <h2>SOBRE NOSOTRXS</h2>
          <p>
            CIUDADES DE OCTUBRE es una plataforma de investigación crítica y
            difusión ampliada sobre la ciudad, el territorio y sus historias.
            Ocupa reflexivamente la representación y los medios de difusión para
            cruzar problemas de la ciudad con los de la arquitectura. Los
            primeros trabajos colectivos datan del 2016, pero en octubre de 2019
            se consolidan como grupo en reacción a las demandas ciudadanas de
            las revueltas sociales ligadas a problemáticas urbanas, políticas e
            históricas. La plataforma extiende y difunde sus investigaciones a
            la esfera pública, utilizando formatos y registros que amplían la
            investigación académica tradicional.
          </p>
          <p>
            <span className="bold">Integrantes del colectivo:</span>
            <br />
            Francisco Cardemil Pérez – Alejandra Celedon Förster – Consuelo
            Contreras Maya — Nicolás Navarrete Carrasco — Francisca Quezada
            Mórtola — Felipe Pizarro
          </p>
          <p>
            <span className="bold">Colaboradores web:</span>
            <br />
            Kathryn Gillmore (Diseño)
            <br />
            María Fernanda Sepúlveda (Programación)
            <br />
            Mario Arriagada (Asistente de catalogación)
          </p>
          <p>
            <img src={logoFondart} alt="Logo fondart" />
          </p>
        </section>
        <hr className="dashed-red"></hr>
        {/* <section>
          <h2>Sobre la página web</h2>
          <p>La página consta de tres secciones, separadas en el menú superior de izquierda a derecha: las Cápsulas, las Narrativas y el Archivo. Las Cápsulas consisten en ejes temáticos específicos sobre la ciudad, que bajo su esfera conceptual agrupan distintos materiales de archivo. Estos ejes temáticos son, por ejemplo, las ‘políticas’, la ‘industria’, la ‘vivienda’ o las ‘periferias’. Las Narrativas, por otro lado, corresponden a breves ensayos, compilados por el equipo de Ciudades de Octubre, que desarrollan en mayor profundidad un aspecto o ‘ciudad’ específica dentro de la ciudad. Estos ensayos combinan elementos gráficos, material de archivo intervenido y textos. Finalmente, el Archivo está constituido por todo el material documental compilado, navegable a través de un buscador. Este acervo abarca contenido de diferentes medios, incluyendo prensa, fotografía, planimetrías, artículos académicos, documentos de trabajo, documentos audiovisuales, memorabilia, entre otros.</p>
        </section> */}
        <section>
          <h2>AGRADECIMIENTOS</h2>
          <p>
            <p>
              Agradecemos el apoyo de quienes hicieron posible el desarrollo de
              este proyecto:
            </p>

            <p>
              A los estudiantes del primer Taller de Investigación Estado de
              Excepción, cuyo trabajo de compilación y dibujo constituyó la
              semilla fundacional de este proyecto: Francisco Cardemil, Consuelo
              Contreras Maya, Catalina de Cortillas, María José González,
              Teresita Llodrá, Sofía Mingo, Nicolás Navarrete, Amaranta Palma,
              Francisca Quezada y Andrés Reyes, además de su profesora Alejandra
              Celedón e instructora Eneritz Hernández.
            </p>

            <p>
              A los estudiantes del Taller de Investigación Non-Plan de la
              Escuela de Arquitectura UC, cuyo trabajo de recopilación y dibujo
              fue esencial para el crecimiento de este archivo: Vicente
              Astudillo, Ciro Barraza, Sarahí Delgado, Valeria Guiñez, Tomás
              Keymer, Cristóbal Montecinos, Diego Ortiz, Álvaro Pavez, Colomba
              Pino, Felipe Pizarro y José Luis Toro, además de su profesora
              Alejandra Celedón e instructores Nicolas Navarrete y Francisco
              Cardemil A los estudiantes del Magíster de Arquitectura y el
              Magíster de Proyecto Urbano UC que participaron del workshop La
              Ciudad de las Fábricas: Sofía Arriagada, Mario Arriagada, Amanda
              Celedón, Santiago Eguiguren, Juan Huaiquín, Cristóbal Larraín,
              Kristel Malonnek, Freia Präger y Catalina Tello.
            </p>
            <p>
              A la Subdirección de Investigación y Desarrollo de la Escuela de
              Arquitectura UC por la adjudicación del Fondo Semilla ARQ UC
              Convocatoria 2020.
            </p>
            <p>
              A Javier Correa, Nicolás Stutzin, Francisco Díaz, Serena
              Dambrosio, Hugo Pérez, Cristian Saavedra, Rayna Razmilic, Luis
              Eduardo Bresciani L., Isabel Sierralta.
            </p>
            <p>
              Proyecto financiado por el Fondo Nacional de Desarrollo Cultural y
              las Artes (FONDART), convocatoria 2021. Folio 631295.
            </p>
          </p>
        </section>
        {/* <div className="btn-primary" onClick={()=> navigate('/contact')}>¿Quieres colaborar?</div> */}
      </div>
    </div>
  );
}
