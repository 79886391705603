import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import grayPlaceholder from '../img/gray-placeholder.svg';
import { documentTypes } from '../modules/glossary';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
const axios = require('axios').default;

export default function ArchiveItemDetail (){
  const { state } = useLocation();
  const [archiveItem, setArchiveItem] = useState(null);
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  useEffect(() => {
    if (!state) {
      const urlParams = new URLSearchParams(window.location.search);
        axios.post('https://cdo-api.onrender.com/api/archiveItem', { query: urlParams.get('search') })
          .then(response => {
            setArchiveItem(response.data);
          });
    } else {
      setArchiveItem(state);
    }
  }, [state]);

  function openNewTab(url) { window.open(url, "_blank"); }

  if (archiveItem) {
    const docType = documentTypes.filter(doc => doc.id === archiveItem.documentType);
    return (<>
    <div className="breadcrumb">{ docType.length > 0 ? docType[0].displayName : 'No especificado' } / {archiveItem.referenceCode}</div>
    <div className="item-content">
      <div className="item-description">
        <div className="item-body">
          <h1>{archiveItem.title}</h1>
          <h2>{archiveItem.author !== '' ? archiveItem.author : 'Autor no especificado' }</h2>
          <br/>
          <p className="highlight-text">{ archiveItem.shortDescription }</p>
          <hr className="hr-dashed" />
          <p><span className="bold">Fuente:</span>  {archiveItem.originSource}</p>
          <p><span className="bold">Fecha:</span>  { archiveItem.dateCustom ? archiveItem.dateCustom : (new Date(archiveItem.date)).toLocaleDateString('es-CL', options)}</p>
          <p><span className="bold">Descripción fisica:</span>  {archiveItem.physicalDescription}</p>
          <p><span className="bold">Archivo de origen</span> {archiveItem.originSource}</p>
          <p><span className="bold">Palabras Claves:</span>  {archiveItem.keywords}</p>
          <hr className="hr-line"/>
          <p><span className="bold">Derechos de autor:</span>  { archiveItem.copyrights !== '' ? archiveItem.copyrights : 'No hay información'}</p>
        </div>
        <div className="item-footer">
          <button
            className="archive-download-btn"
            onClick={() => openNewTab(archiveItem.url ? archiveItem.url : archiveItem.dropboxLink)}
          >Ir al documento</button>
          <div className="social-share">
            <EmailShareButton
              url={window.location.href}
              subject={"Ciudades de Octubre"}
              body={"#CiudadesDeOctubre"}
            >
              <EmailIcon size={16} round />
            </EmailShareButton>
            <TwitterShareButton
              url={window.location.href}
              title={archiveItem.title}
              via={"Ciudades de Octubre"}
              hashtags={["#CiudadesDeOctubre"]}
              related={["https://twitter.com/FADEU_UC"]}
            >
              <TwitterIcon size={16} round />
            </TwitterShareButton>
            <FacebookShareButton
              url={window.location.href}
              quote={"Ciudades de Octubre"}
              hashtag={"#CiudadesDeOctubre"}
            >
              <FacebookIcon size={16} round />
            </FacebookShareButton>
          </div>
        </div>
      </div>
      <div className="item-image">
        <img src={archiveItem.thumbnail ? archiveItem.thumbnail : grayPlaceholder } alt="Preview" />
      </div>
    </div>
    </>)
  } else {
    return (<>
    <div className="loader-content">
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </>)
  }
}
