const axios = require('axios').default;
const api = axios.create({
    baseURL: 'https://cdo-api.onrender.com/api',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
    }
});

export async function getAllDocuments() {
    try{
        const response = await api.get('/blogs');
        return response.data;
    }catch(error) {
        console.log(error);
        return [];
    }
}
