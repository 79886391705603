import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import grayPlaceholder from '../img/gray-placeholder.svg';
const axios = require('axios').default;


export default function BlogExample() {
  const [blogEntries, setBlogEntries] = useState(0);
  useEffect(() => {
    axios.get('https://cdo-api.onrender.com/api/blogs')
      .then(response => {
        setBlogEntries(response.data[0]);
      });
   }, []);

    return (
        <>
          <div className="blog-example">
            <BlogContent entry={blogEntries} />
          </div>
        </>
      );
  
}

function BlogContent({entry}) {

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

  if(entry === 0) {
    return (<>
    <div className="loader-content">
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </>)
  }
  
  return (<>
    <div className="breadcrumb">
        <Link to="/">Inicio</Link> | <Link to="/blogexample">{entry.title}</Link>
    </div>
    <div>
        <h2 className="page-title">{entry.title}</h2>
        <div><img src={entry.thumbnail ? entry.thumbnail : grayPlaceholder } /></div>
        <iframe src="https://lumpenprojects.com/A-Magazine-Curated-by-Erdem"></iframe>
    </div>
    <div className="button-end-bex">
        <div 
            className="button-end-bex-btn"
            onClick={() => openInNewTab('https://lumpenprojects.com/A-Magazine-Curated-by-Erdem')}
        >Ver en tamaño completo →</div>
    </div>
  </>)
}
