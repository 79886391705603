import { useNavigate } from 'react-router-dom';
import grayPlaceholder from '../img/gray-placeholder.svg';

export default function ArchiveItem({item}) {
    const navigate = useNavigate();
    return(<>
      <div
        className="archive-item-result"
        onClick={()=> navigate(`/item?search=${item.referenceCode}`, {state: item}) }
      >
        <img src={item.thumbnail ? item.thumbnail : grayPlaceholder } className="archive-item-image" alt={item.thumbnail ? item.thumbnail : grayPlaceholder } />  
        <h4 className="archive-item-title">{item.title}</h4>
        <h5 className="archive-item-subtitle">{item.subtitle}</h5>
      </div>
    </>)
  }