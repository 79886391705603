import React from "react";
import narrativas from '../img/narrativas.svg';
import { useEffect, useState } from "react";
import { getAllDocuments } from "../services/BlogService";

export default function Home ({children}) { 
  const [blogImage, setBlogImage] = useState(narrativas);
  const [mapImage, setMapImage] = useState(<img src={narrativas} alt="Narrativa" className="home__blogImg"/>);
  const [blogEntries, setBlogEntries] = useState([]);

  useEffect(() => {
    const getInitialState = async () => {
      const documentsData = await getAllDocuments();
      setBlogEntries(documentsData);
    }

    if (blogEntries.length === 0) {
      getInitialState();
    }
  }, [blogEntries]);

  useEffect(() => {
    setMapImage(<img src={blogImage} alt="Narrativa" className="home__blogImg"/>);
  }, [blogImage])

  function handleMouseEnter(newImage) {
    setBlogImage(newImage);
  }

  const blogHoverable = blogEntries.map(entry => (
    <a
      href={entry.externalLink}
      key={entry._id}
      onMouseEnter={() => handleMouseEnter(entry.thumbnail)}
    ><div>{entry.title}</div></a>
  ));

  return (
      <>
        <div className="home-landing">
          { children }
        </div>
        <section className="home__content">
          <nav className="home__nav">
            { blogHoverable }
          </nav>
          { mapImage }
        </section>
      </>
    )
}
