import React, { useEffect, useState, useRef } from "react";
import ArchiveItem from "./ArchiveItem";
import { Link } from "react-router-dom";
const axios = require('axios').default;

export default function Subdivisions() {
  const [archiveItems, setArchiveItems] = useState([]);
  const [sortType, setSortType] = useState('default');
  const azRef = useRef(null);
  const zaRef = useRef(null);
  const ascRef = useRef(null);
  const desRef = useRef(null);

  useEffect(() => {
    if (sortType === "default"){
      axios.post('https://cdo-api.onrender.com/api/searchArchive', { query: "Periferias" })
      .then(response => {
        setArchiveItems(response.data);
      });
    }

    if (sortType === "AZ") {
      setArchiveItems([...archiveItems].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))));
      azRef.current.style.textDecoration = 'line-through #E23340';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "ZA") {
      setArchiveItems([...archiveItems].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))).reverse());
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'line-through #E23340';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "ASC") {
      setArchiveItems([...archiveItems].sort((a, b) => new Date(b.date) - new Date(a.date)).reverse());
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'line-through #E23340';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "DES") {
      setArchiveItems([...archiveItems].sort((a, b) => new Date(b.date) - new Date(a.date)));
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'line-through #E23340';
    }
   }, [sortType]);

  return (
    <>
    <div className="breadcrumb">
      <Link to="/">Inicio</Link> | <Link to="/periferias">Periferias</Link>
      </div>
      <div className="housing-intro">
        <h2 className="page-title">Periferias</h2>
        <p>Más que un tejido, la periferia de Santiago es una condición. Son zonas marginadas que se caracterizan por la falta de acceso a servicios básicos, infraestructuras deficientes, altos índices de pobreza, violencia y escasa presencia del Estado. La historia de las periferias es un ciclo de exclusión y vulnerabilidad, donde las poblaciones han persistentemente luchado por acceder a ciudad.</p>
      </div>
      <hr className="dashed-red opacity05"></hr>
      <div className="sort-options">
        <div className="sort-options-title">Ordenar</div>
        <div className="sort-dropdown">
          <div onClick={() => setSortType("AZ")} ref={azRef} >A-Z</div>
          <div onClick={() => setSortType("ZA")} ref={zaRef}>Z-A</div>
          <div onClick={() => setSortType("ASC")} ref={ascRef}>Fecha ASC</div>
          <div onClick={() => setSortType("DES")} ref={desRef}>Fecha DES</div>
        </div>
      </div>
      <div className="archive-results-content">
        <ArchiveItemContent archiveItems={archiveItems} />
      </div>
    </>
  );
}

function ArchiveItemContent({archiveItems}) {
  if(archiveItems.length === 0) {
    return (<>
    <div className="loader-content">
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </>)
  }

  return (<>
    {archiveItems.map(archive => <ArchiveItem key={archive._id} item={archive}/>)}
  </>)
}
