import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { documentTypes } from '../modules/glossary';
import close from '../img/cancel.svg';

export default function ArchiveMenu({titles, glossary}) {
  const navigate = useNavigate();
  const [visibleArchiveMenu, setVisibleArchiveMenu] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [glossaryItems, setGlossaryItems] = useState([]);
  const specialSearch = ['POLITICAS', 'TERRITORIO', 'VIVIENDA', 'PERIFERIAS', 'INDUSTRIA'];

  useEffect(() => {
    if (glossary) {
      glossary.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
  
        return 0;
      });
      setGlossaryItems(glossary.filter(item => item !== ""));
    }
  }, [glossary]);

  function handleChangeType(e) {
    const selectedTypes = [...document.querySelectorAll(".doc-type-checkbox")].filter(item => item.checked === true ).map(item => item.name);
    const filteredGlossary = glossary? glossary.filter(item => {
      let isType = false;
      selectedTypes.forEach(el => {
        if(item.types.includes(el)) {
          isType = true;
        }
      });
      return isType;
    }) : [];
    setGlossaryItems(filteredGlossary)
 };

  function handleSearchBar(e) {
    if(e.key === 'Enter') {
      handleArchiveQuery();
    }
  }

  function handleArchiveQuery() {
    if(specialSearch.includes(searchQuery.toUpperCase())) {
      navigate(`/${searchQuery.toLocaleLowerCase()}`)
    } else {
      navigate(`/archive?search=${searchQuery}`, {state: searchQuery})
    }
  }

  function handleGlossaryQuery(glossaryItem) {
    if(specialSearch.includes(glossaryItem.name.toUpperCase())) {
      navigate(`/${glossaryItem.name.toLocaleLowerCase()}`)
    } else {
      navigate(`/glossary?search=${glossaryItem.name}`, {state: glossaryItem.name})
    }
  }

  return (
    <>
      <div
        className='nav--hovereable'
        id="headerArchiveMenu"
      >
        <li onClick={() => {
          handleArchiveMenu(visibleArchiveMenu);
          setVisibleArchiveMenu(!visibleArchiveMenu)
        }}>
          <span>Archivo</span>
        </li>
        <div id="headerArchiveMenuSide">
          <div className='archive-menu-side__close'>
            <div onClick={() => {
              handleArchiveMenu(visibleArchiveMenu);
              setVisibleArchiveMenu(!visibleArchiveMenu)
            }}>
              <img src={close} alt="Close" className='closeArchiveImg' />
            </div>
          </div>
          <div className='archive-menu-side__content'>
            <div>
              <h3>Archivo</h3>
              <hr className='dashed' />
              <div className='archive-menu-side__filters'>
                <h4>Por tipo de documento:</h4>
                <hr className='line-separator'/>
                <div className='filters__document-type'>
                  {documentTypes.map(type => {
                    return(
                    <label className="form-control" key={type.id} name={type.id}>
                      <input className="doc-type-checkbox" type="checkbox" name={type.id} onChange={handleChangeType} defaultChecked="true" />
                      { type.displayName }
                    </label>)
                  })}
                </div>
              </div>
            </div>
            <div className='archive-menu-side__glossary'>
              { glossaryItems ? glossaryItems.map(glossaryItem => {
                return(
                  <div
                    key={glossaryItem.name}
                    onClick={()=> handleGlossaryQuery(glossaryItem) }
                  >
                  
                  {glossaryItem.name}
                </div>)
                })  : <></>
              }
            </div>
            <hr className='line-separator'/>
            <div className='archive-menu-side__searchbar'>
              <label>Buscar en archivo</label>
              <input
                type="text"
                name="archive-search"
                value={searchQuery || ''}
                list="titles"
                onChange={e => setSearchQuery(e.target.value)}
                onKeyDown={e => handleSearchBar(e)}
              />
              <datalist id="titles">
                { titles ? titles.map(title=> <option key={title}>{title}</option>) : <></>}
              </datalist>
              <div
                className='archive-search-button'
                onClick={()=> handleArchiveQuery() }
              >
                <span className="material-symbols-outlined">search</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

function handleArchiveMenu(visibleArchiveMenu) {
  const menuSide = document.getElementById("headerArchiveMenuSide");
  menuSide.style.display = !visibleArchiveMenu ? 'block' : 'none';
}
