export default function Acknowledgment() {
  return (
      <div className="about">
        <section>
        <h2>Agradecimientos</h2>
        <p>
          Agradecemos el apoyo de todas las personas, organizaciones e instituciones que hicieron posible el desarrollo de este proyecto.
          <br/><br/>
          A los estudiantes del primer Taller de Investigación Estado de Excepción, cuyo trabajo de compilación y dibujo constituyó la semilla fundacional de este proyecto: Francisco Cardemil, Consuelo Contreras Maya, Catalina de Cortillas, María José González, Teresita Llodrá, Sofía Mingo, Nicolás Navarrete, Amaranta Palma, Francisca Quezada y Andrés Reyes, además de su profesora Alejandra Celedón e instructora Eneritz Hernández. 
          <br/><br/>
          A los estudiantes del Taller de Investigación Non-Plan de la Escuela de Arquitectura UC, cuyo trabajo de recopilación y dibujo fue esencial para el crecimiento de este archivo: Vicente Astudillo, Ciro Barraza, Sarahí Delgado, Valeria Guiñez, Tomás Keymer, Cristóbal Montecinos, Diego Ortiz, Álvaro Pavez, Colomba Pino, Felipe Pizarro y José Luis Toro, además de su profesora Alejandra Celedón e instructores Nicolas Navarrete y Francisco Cardemil
          <br/><br/>
          A los estudiantes del Magíster de Arquitectura y el Magíster de Proyecto Urbano UC que participaron del workshop La Ciudad de las Fábricas: Sofía Arriagada, Mario Arriagada, Amanda Celedón, Santiago Eguiguren, Juan Huaiquín, Cristóbal Larraín, Kristel Malonnek, Freia Präger y Catalina Tello.
          <br/><br/>
          A la Subdirección de Investigación y Desarrollo de la Escuela de Arquitectura UC por la adjudicación del Fondo Semilla ARQ UC Convocatoria 2020.
          <br/><br/>
          A Javier Correa, Nicolás Stutzin, Francisco Díaz, Serena Dambrosio, Hugo Pérez, Cristian Saavedra, Rayna Razmilic,  Luis Eduardo Bresciani L., Isabel Sierralta.
          <br/><br/>
          Proyecto financiado por el Fondo Nacional de Desarrollo Cultural y las Artes (FONDART), convocatoria 2022.
        </p>
        </section>

        <h2>Reconocimiento (by)</h2>
        <p>
          Esta licencia permite cualquier explotación de la obra, incluyendo una finalidad comercial, así como la creación de obras derivadas, la distribución de las cuales también está permitida sin ninguna restricción, con la única condición de que se haga referencia expresa al autor, es decir, que aparezca su nombre en cualquier uso o acto de explotación que se haga de la obra. Recordemos que la obligación de reconocimiento está presente en todas las licencias Creative Commons.
        </p>

        <h2>Reconocimiento - No Comercial (by-nc)</h2>
        <p>Esta licencia permite la generación de obras derivadas siempre que no se haga un uso comercial de las mismas. Tampoco se puede utilizar la obra original con finalidades comerciales.</p>

        <h2>Dominio Público</h2>
        <p>Este documento pertenece al patrimonio cultural común, por lo que puede ser utilizado y reproducido libremente.</p>

      </div>
  )
}