import React, { useEffect, useState, useRef } from "react";
import ArchiveItem from "./ArchiveItem";
import { Link } from "react-router-dom";
const axios = require('axios').default;

export default function Territory() {
  const [archiveItems, setArchiveItems] = useState([]);
  const [loading, setLoading] = useState([]);
  const [sortType, setSortType] = useState('default');
  const azRef = useRef(null);
  const zaRef = useRef(null);
  const ascRef = useRef(null);
  const desRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    if(sortType === 'default') {
      axios.post('https://cdo-api.onrender.com/api/searchArchiveByKeyword', { query: "Territorio" })
        .then(response => {
          setArchiveItems(response.data);
          setLoading(false);
        });
    }

    if (sortType === "AZ") {
      setArchiveItems([...archiveItems].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))));
      azRef.current.style.textDecoration = 'line-through #E23340';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "ZA") {
      setArchiveItems([...archiveItems].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))).reverse());
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'line-through #E23340';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "ASC") {
      setArchiveItems([...archiveItems].sort((a, b) => new Date(b.date) - new Date(a.date)).reverse());
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'line-through #E23340';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "DES") {
      setArchiveItems([...archiveItems].sort((a, b) => new Date(b.date) - new Date(a.date)));
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'line-through #E23340';
    }
   }, [sortType]);

  return (
    <>
      <div className="breadcrumb">
        <Link to="/">Inicio</Link> | <Link to="/territorio">Territorio</Link>
      </div>
      <div className="housing-intro">
        <h2 className="page-title">Territorio</h2>
        <p>El territorio es la base física sobre la cual se asienta la ciudad, el cual enfrenta desafíos, tensiones y disputas. Construye el problema del suelo urbano, de su asignación de valor, de la propiedad, de los derechos de uso, de la especulación del que es sujeto y de los desplazamientos que conllevan esos procesos.</p>
      </div>
      <hr className="dashed-red opacity05"></hr>
      {archiveItems.length > 0 ?
        (<div className="sort-options">
          <div className="sort-options-title">Ordenar</div>
          <div className="sort-dropdown">
            <div onClick={() => setSortType("AZ")} ref={azRef} >A-Z</div>
            <div onClick={() => setSortType("ZA")} ref={zaRef}>Z-A</div>
            <div onClick={() => setSortType("ASC")} ref={ascRef}>Fecha ASC</div>
            <div onClick={() => setSortType("DES")} ref={desRef}>Fecha DES</div>
          </div>
        </div>) : <> </>}
      <div className="archive-results-content">
        <ArchiveItemContent archiveItems={archiveItems} loading={loading} />
      </div>
    </>
  );
}

function ArchiveItemContent({archiveItems, loading}) {
  if(archiveItems.length === 0 && loading) {
    return (<>
    <div className="loader-content">
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </>)
  }

  if(archiveItems.length === 0) {
    return (<>
      No hay resultados en el archivo con la palabra clave Territorio.
    </>)
  }

  return (<>
    {archiveItems.map(archive => <ArchiveItem key={archive._id} item={archive}/>)}
  </>)
}
