export const documentTypes = [
    {
      displayName: 'Prensa',
      id: 'PR'
    }, 
    {
      displayName: 'Planos',
      id: 'PL'
    }, 
    {
      displayName: 'Fotografías',
      id: 'FO'
    }, 
    {
      displayName: 'Objetos',
      id: 'OB'
    }, 
    {
      displayName: 'Artículos',
      id: 'AR'
    }, 
    {
      displayName: 'Libros',
      id: 'LI'
    },
    {
      displayName: 'Documentos',
      id: 'DG'
    },
    {
      displayName: 'Tesis',
      id: 'TE',
    },
    {
      displayName:'Videos',
      id: 'VI'
    }
  ]