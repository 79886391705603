import React, { useState, useEffect } from 'react';
import './style/App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Archive from './components/Archive';
import Header from './components/Header';
import Home from './components/Home';
import Housing from './components/Housing';
import Politics from './components/Politics';
import Industry from './components/Industry';
import Subdivisions from './components/Subdivisions';
import Territory from './components/Territory';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import BlogExample from './components/BlogExample';
import ArchiveItemDetail from './components/ArchiveItemDetail';
import GlossarySearch from './components/GlossarySearch';
import { getAllDocuments } from "./services/BlogService";
import { getBaseConfig } from "./services/BaseService";
import Acknowledgment from './components/Acknowlegment';

function App() {
  const [blogEntries, setBlogEntries] = useState([]);
  const [baseState, setBaseState] = useState({});

  useEffect(() => {
    const getInitialState = async () => {
      const documentsData = await getAllDocuments();
      const baseData = await getBaseConfig();
      setBlogEntries(documentsData);
      setBaseState(baseData);
    }

    if (blogEntries.length === 0 && !baseState.landing) {
      getInitialState();
    }
  }, [blogEntries, baseState]);


  return (
    <Router>
      <Header blogEntries={blogEntries} config={baseState} />
      <main className='main__content'>
        <Routes>
          <Route path="/archive" element={<Archive />} />
          <Route path="/politicas" element={<Politics />} />
          <Route path="/vivienda" element={<Housing />} />
          <Route path="/industria" element={<Industry />} />
          <Route path="/periferias" element={<Subdivisions />} />
          <Route path="/territorio" element={<Territory />} />
          <Route path="/glossary" element={<GlossarySearch />} />
          <Route path="/about" element={<About><AboutUsComponent/></About>}/>
          <Route path="/contact" element={<Contact />} />
          <Route path="/item" element={<ArchiveItemDetail />} />
          <Route path="/blogexample" element={<BlogExample />} />
          <Route path="/agradecimientos" element={<Acknowledgment />} />
          <Route index element={<Home><VideoComponent /></Home>} />
        </Routes>
      </main>
      <Footer />
    </Router>
  )
}

let currentUrl = null;
function VideoComponent () {
  const [baseState, setBaseState] = useState({});

  useEffect(() => {
    const getInitialState = async () => {
      const baseData = await getBaseConfig();
      setBaseState(baseData);
    }

    if (!baseState.landing) {
      getInitialState();
    }
  }, [baseState]);

  function getRandomFromArray(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  if (currentUrl === null && baseState && baseState.landing) {
    currentUrl =  getRandomFromArray(baseState.landing);
  }

  const videoURL = baseState && baseState.landing ? currentUrl : "https://res.cloudinary.com/dg5ewcada/video/upload/v1680122146/921849158_yafrzk.mp4";

  return (
    <video muted autoPlay loop id="myVideo" key={videoURL}>
      <source src={videoURL} type="video/mp4" />
    </video>
  );
}

function AboutUsComponent () {
  const [baseState, setBaseState] = useState({});

  useEffect(() => {
    const getInitialState = async () => {
      const baseData = await getBaseConfig();
      setBaseState(baseData);
    }

    if (!baseState.landing) {
      getInitialState();
    }
  }, [baseState]);

  function getRandomFromArray(arr) {
    return arr[Math.floor(Math.random() * arr.length)];
  }

  if (currentUrl === null && baseState && baseState.landing) {
    currentUrl =  getRandomFromArray(baseState.landing);
  }

  const videoURL = baseState && baseState.landing ? currentUrl : "https://res.cloudinary.com/dg5ewcada/video/upload/v1680122146/921849158_yafrzk.mp4";

  return (
    <video muted autoPlay loop id="aboutVideo" key={videoURL}>
      <source src={videoURL} type="video/mp4" />
    </video>
  );
}

export default App;
