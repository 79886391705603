import React, { useEffect, useState } from "react";
import { useRef } from 'react';
import ArchiveItem from "./ArchiveItem";
import { Link } from "react-router-dom";
const axios = require('axios').default;

export default function Housing() {
  const [housings, setHousings] = useState([]);
  const [sortType, setSortType] = useState('default');
  const azRef = useRef(null);
  const zaRef = useRef(null);
  const ascRef = useRef(null);
  const desRef = useRef(null);


  useEffect(() => {
    if (sortType === "default") {
      axios.post('https://cdo-api.onrender.com/api/searchArchiveByKeyword', { query: "Vivienda" })
      .then(response => {
        setHousings(response.data);
      });
    }

    if (sortType === "AZ") {
      setHousings([...housings].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))));
      azRef.current.style.textDecoration = 'line-through #E23340';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "ZA") {
      setHousings([...housings].sort((a, b) => a.title.replace("\"","").localeCompare(b.title.replace("\"",""))).reverse());
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'line-through #E23340';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "ASC") {
      setHousings([...housings].sort((a, b) => new Date(b.date) - new Date(a.date)).reverse());
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'line-through #E23340';
      desRef.current.style.textDecoration = 'none';
    } else if (sortType === "DES") {
      setHousings([...housings].sort((a, b) => new Date(b.date) - new Date(a.date)));
      azRef.current.style.textDecoration = 'none';
      zaRef.current.style.textDecoration = 'none';
      ascRef.current.style.textDecoration = 'none';
      desRef.current.style.textDecoration = 'line-through #E23340';
    }

   }, [sortType]);

  return (
    <>
      <div className="content-header-options">
        <div className="breadcrumb">
          <Link to="/">Inicio</Link> | <Link to="/vivienda">Vivienda</Link>
        </div>
      </div>
      <div className="housing-intro">
        <h2 className="page-title">Vivienda</h2>
        <p>La historia de la vivienda en Chile es una de precariedad. Sin pretender ofrecer un relato inacabo la aproximación que aquí se promueve es una en base episodios muchas veces recurrentes. Desde las tomas y los campamentos a la autoconstrucción, desde el lote con servicios a la incrementalidad y el subisidio, se exponen las maneras en que el problema de la vivienda ha sido enfrentado y ha construido la ciudad presente.</p>
      </div>
      <hr className="dashed-red opacity05"></hr>
      <div className="sort-options">
        <div className="sort-options-title">Ordenar</div>
        <div className="sort-dropdown">
          <div onClick={() => setSortType("AZ")} ref={azRef} >A-Z</div>
          <div onClick={() => setSortType("ZA")} ref={zaRef}>Z-A</div>
          <div onClick={() => setSortType("ASC")} ref={ascRef}>Fecha ASC</div>
          <div onClick={() => setSortType("DES")} ref={desRef}>Fecha DES</div>
        </div>
      </div>
      <div className="archive-results-content">
        <HousingsContent housings={housings} />
      </div>
    </>
  );
}

function HousingsContent({housings}) {
  if(housings.length === 0) {
    return (<>
    <div className="loader-content">
      <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
    </>)
  }

  return (<>
    {housings.map(archive => <ArchiveItem key={archive._id} item={archive}/>)}
  </>)
}
